@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --orange: #ff914d;
}
.bg-theme-orange {
  background-color: var(--orange);
}
.text-theme-oragne {
  color: #ff914d;
}
.text-theme-grey {
  color: rgb(30, 41, 59);
}
.bg-cover-image {
  background-image: url("./background-cover.png");
  background-position: 0px 516px;
  background-size: 82% auto;
}
.bg-cover {
  background-image: url("./cover.png");
  background-position: 0px 516px;
  background-size: 82% auto;
  height: 400px;
}
.bg-about {
  background-image: url("./cover-about.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 1200px) {
  .bg-cover {
    height: 500px;
  }
}
@media screen and (max-width: 935px) {
  .bg-cover {
    height: 650px;
  }
}
.font-family {
  font-family: "Seaweed Script", sans-serif;
}
.bg-cover-image-2 {
  background-image: url("./cover-2.png");
  background-position: 0px 516px;
  background-size: cover;
}
.bg-gradient {
  background-image: linear-gradient(294deg, #ffffff 53%, #f7c8acf2 0%);
}
